<template>
     <v-container class="mt-12 my-2 mx-2 px-3 col-md-7">
     <div class="">
       <!-- <div class="text-center">
      <v-col>
        <v-btn class="ma-2" rounded color="success" @click="getMony">
          <span> {{ $t("globals.getUpdate") }} </span>
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-col>
    </div>
          <v-layout>
      <v-row no-gutters style=";">
       
        <v-flex>
          <v-col>
            <router-link to="">
              <v-card class="pa-2 backss" outlined shaped color="green">
              
                <span style="height:100%  "
                  ><v-icon :size="60" class="grey--text rotaticon"
                    >mdi-currency-usd-circle</v-icon
                  ></span
                >
                <span class=""
                  ><strong>{{ $t("earn.today") }}</strong
                  ><br />
                   <span class="ml-15 pl-15 white--text" style="font-size:23px">
                  {{todays }}$
                </span>
                  </span
                >
              </v-card>
            </router-link>
          </v-col>
        </v-flex>
        <v-flex>
          <v-col>
            <router-link to="">
              <v-card class="pa-2 backss" outlined shaped color="red">
                
                <span style="height:100%  "
                  ><v-icon :size="60" class="grey--text rotaticon"
                    >mdi-currency-usd</v-icon
                  ></span
                >
                <span class=""
                  ><strong>{{ $t("earn.yestrday") }}</strong
                  ><br />
                   <span class="ml-15 pl-15 white--text" style="font-size:23px">
                  {{ yesterdays }}$
                </span>
                  </span
                >
              </v-card>
            </router-link>
          </v-col>
        </v-flex>
        <v-flex>
          <v-col>
            <router-link to="">
              <v-card class="pa-2 backss" outlined shaped color="blue">
               
                <span style="height:100%  "
                  ><v-icon :size="60" class="grey--text rotaticon"
                    >mdi-currency-usd-circle-outline</v-icon
                  ></span
                >
                <span class=""
                  ><strong>{{ $t("earn.earn") }}</strong
                  ><br />
                   <span class="ml-15 pl-15 white--text" style="font-size:23px">
                  {{ allearn }}$
                </span>
                  </span
                >
              </v-card>
            </router-link>
          </v-col>
        </v-flex>
      </v-row>
          </v-layout> -->
          <hr>
          <v-card class="ma-3 pa-2  ">
              <h3>how earn : </h3>
              <span><v-icon>mdi-transition-masked</v-icon></span><strong>{{$t('earn.in1')}}</strong><br>
              <span><v-icon>mdi-transition-masked</v-icon></span><strong>{{$t('earn.in2')}}</strong><br>
              <span><v-icon>mdi-transition-masked</v-icon></span><strong>{{$t('earn.in3')}}</strong><br>
              <hr>
              <v-col cols="12" sm="12" md="12" class="pa-0 mt-5">
            <v-text-field
              :label="$t('signup.form.email')"
              outlined
              
              dense
              clearable
              prepend-inner-icon="mdi-email-outline"
            ></v-text-field>
            
          </v-col>

           <v-col cols="12" sm="12" md="12" class="pa-0 mt-4 text-center">
            <v-btn
              class="col-md-12"
              dense
              
              color="success"
              
              :dark="!loading"
              elevation="3"
              :loading="loading"
            >
              <v-icon>mdi-currency-usd</v-icon> {{ $t("earn.whit") }}
            </v-btn>
          </v-col>
          </v-card>
    </div>
     </v-container>
</template>
<script>
export default {
    data()
    {
      return{
            allearn: "",
    todays: "",
    yesterdays: ""
      }
    },
    methods:{
      getMony : function () {
         let AuthStr = "Bearer ".concat(this.$store.getters.getToken);
        this.$http.get("/profit/dash/" + this.$store.getters.getUserId, {
        headers: {
          Authorization: AuthStr,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res)=>
      {
          this.allearn= res.data.allearn.toString().substr(0, 5);
          this.todays= res.data.todays.toString().substr(0, 5);
          this.yesterdays =res.data.yesterdays.toString().substr(0, 5)
      })
      }
    },
    mounted(){
       this.getMony();
    }
}
</script>